<template>
  <!--eslint-disable-->
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <div class="page-wrapper login">
      <div class="page-inner bg-brand-gradient">
        <div class="page-content-wrapper bg-transparent m-0">
          <div class="height-10 w-100 shadow-lg px-4 bg-brand-gradient">
            <div class="d-flex align-items-center container p-0">
              <div
                  class="page-logo width-mobile-auto m-0 align-items-center justify-content-center p-0 bg-transparent bg-img-none shadow-0 height-9">
                <a href="javascript:void(0)"
                   class="page-logo-link press-scale-down d-flex align-items-center">
                  <img :src="publicPath + 'media/birthday/logo_birthday_2023.png'" class="logo-edutalk" alt="Edutalk Tư Vấn"
                       aria-roledescription="logo">
                  <span class="page-logo-text mr-1"></span>
                </a>
              </div>
            </div>
          </div>
          <div class="flex-1"
               :style="{ background: 'url(media/svg/pattern-1.svg) no-repeat center bottom fixed', backgroundSize: 'cover'}">
            <div class="container py-4 py-lg-5 my-lg-5 px-4 px-sm-0">
              <div class="row">
                <div class="col col-md-6 col-lg-7 hidden-sm-down">
                  <h2 class="fs-xxl fw-500 mt-4 text-white">
                    CÔNG TY CỔ PHẦN <br>
                    TƯ VẤN ĐÁNH GIÁ VÀ PHÁT TRIỂN <br>
                    GIÁO DỤC EDUTALK
                    <br>
                    <br>
                    WEBSITE DÀNH CHO NHÂN SỰ <br> TẠI EDUTALK
                  </h2>
                  <div class="d-sm-flex flex-column align-items-center justify-content-center d-md-block">
                    <div class="px-0 py-1 mt-5 text-white fs-nano opacity-50">
                      Mạng xã hội
                    </div>
                    <div class="d-flex flex-row opacity-70">
                      <a href="https://www.facebook.com/edutalk.edu.vn/" target="_blank" class="mr-2 fs-xxl text-white">
                        <i class="fab fa-facebook-square"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-5 col-xl-5 ml-auto">
                  <div class="card p-4 rounded-plus bg-faded">
                    <h2>Đăng nhập tài khoản</h2>
                    <h5 class="mb-5 text-black-50">Chào mừng bạn đến Edutalk - Nền tảng tìm kiếm và đặt lớp học. Website
                      dành riêng cho <span class="text-danger">Nhân sự</span> tại Edutalk!</h5>

                    <div v-if="errors && errors.length" class="fv-plugins-message-container">
                      <p class="error"><i class="far fa-exclamation-triangle"></i> {{ errors }} </p>
                    </div>

                    <form id="js-login" class="form" @submit.prevent="handleSubmit(onSubmit)">
                      <div class="form-group">
                        <ValidationProvider vid="email" name="Email" rules="required|min:6"
                                            v-slot="{ errors, classes }">
                          <input type="text" ref="email" name="email" class="form-control form-control-lg"
                                 :class="classes"
                                 placeholder="Nhập email hoặc số điện thoại"
                                 v-model=form.email>
                          <div class="fv-plugins-message-container">
                            <div data-field="name" data-validator="notEmpty"
                                 class="fv-help-block">{{ errors[0] }}
                            </div>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="form-group mb-1 ">
                        <ValidationProvider name="Mật khẩu" rules="required|min:6"
                                            v-slot="{ errors, classes }">
                          <input type="password" ref="password" name="password" class="form-control form-control-lg"
                                 :class="classes"
                                 placeholder="Mật khẩu"
                                 v-model=form.password>
                          <div class="fv-plugins-message-container">
                            <div data-field="name" data-validator="notEmpty"
                                 class="fv-help-block">{{ errors[0] }}
                            </div>
                          </div>
                        </ValidationProvider>
                        <div class="col-lg-12 mt-1 text-right">
                          <router-link to="/forgot-password" v-slot="{ href, navigate }">
                            <a :href="href" title="Quên mật khẩu"
                               @click="navigate"
                               data-filter-tags="Quên mật khẩu"
                               class=" waves-effect waves-themed">
                              Quên mật khẩu?
                            </a>
                          </router-link>
                        </div>
                      </div>

                      <div class="row no-gutters">
                        <div class="col-lg-12 pr-lg-1 my-2">
                          <button type="submit" id="login-submit" ref="login-submit"
                                  class="btn btn-primary btn-block btn-lg waves-effect waves-themed">
                            Đăng nhập
                          </button>
                        </div>
<!--                        <el-divider class="or-login">Hoặc</el-divider>-->
<!--                        <div class="col-lg-12 my-2">-->
<!--                          <button type="button" @click="loginFacebook"-->
<!--                                  class="btn btn-social btn-facebook ">-->
<!--                            <img :src="publicPath + 'media/icon-fb.png'" alt="">-->
<!--                            <div class="text-social">Đăng nhập bằng Facebook</div>-->
<!--                          </button>-->
<!--                        </div>-->
<!--                        <div class="col-lg-12 my-2">-->
<!--                          <button type="button" id="js-login-btn"-->
<!--                                  @click="loginGoogle"-->
<!--                                  class="btn btn-social btn-google">-->
<!--                            <img :src="publicPath + 'media/icon-gg.png'" alt="">-->
<!--                            <div class="text-social">Đăng nhập bằng Google</div>-->
<!--                          </button>-->
<!--                        </div>-->

<!--                        <div class="col-lg-12 mt-3 text-center justify-content-edutalk ">Bạn chưa có tài khoản?-->
<!--                          <router-link to="/register" v-slot="{ href, navigate }">-->
<!--                            <a :href="href" title="Đăng ký tài khoản"-->
<!--                               @click="navigate"-->
<!--                               data-filter-tags="Đăng ký tài khoản"-->
<!--                               class=" waves-effect waves-themed">-->
<!--                              Đăng ký ngay-->
<!--                            </a>-->
<!--                          </router-link>-->
<!--                        </div>-->
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="position-absolute pos-bottom pos-left pos-right p-3 text-center text-white">
                2021 © Edutalk by&nbsp;<a href="https://edutalk.edu.vn"
                                          class="text-white opacity-40 fw-500" title="edutalk.edu.vn"
                                          target="_blank">edutalk.edu.vn</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="modal" style='display:none;'>
      <iframe name="theFrame" id="ifram1" style="width: 100%;height: 100%;border: none;" :src="loginPath + 'login?from=noibo'"></iframe>
    </div>
  </ValidationObserver>
</template>
<!-- Load login custom page styles -->

<script>
import {mapGetters, mapState} from "vuex";
import {LOGIN} from "@/core/services/store/auth.module";
import firebase from "firebase";
import {FACEBOOK_LOGIN, GOOGLE_LOGIN, SET_AUTH} from "../../../core/services/store/auth.module";
import {COUNT_IS_WAIT_USER} from "@/core/services/store/profile.module";
import {PUSH_DATA_LAST} from "../../../core/services/store/notification.module";

export default {
  name: "edutalk-login",
    metaInfo: {
        meta: [
            { property: 'og:url', content: 'https://noibo.edutalk.edu.vn' },
            { property: 'og:type', content: 'website' },
            { property: 'og:title', content: 'Dashboard' },
            { property: 'og:description', content: 'Website nội bộ Edutalk' },
            { property: 'og:image', content: 'https://noibo.edutalk.edu.vn/media/birthday/logo_birthday_2023.png' },
        ],
    },
  data() {
    return {
      publicPath: process.env.VUE_APP_BASE_URL,
      loginPath: process.env.VUE_APP_BASE_URL_LOGIN,
      state: "signin",
      // Remove this dummy login info
      form: {
        email: "",
        password: ""
      },
      check_error: false,
      firebaseConfigState: {}
    };
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(["currentUser"]),
  },
  mounted() {
    firebase.remoteConfig
    this.firebaseConfigState = {
      apiKey: "AIzaSyBuvmAcRr5yMLJu4YSqx9kVP-hTQFPpfYM",
      authDomain: "edutalk-71810.firebaseapp.com",
      databaseURL: "https://edutalk-71810.firebaseio.com",
      projectId: "edutalk-71810",
      storageBucket: "edutalk-71810.appspot.com",
      messagingSenderId: "383005973421",
      appId: "1:383005973421:web:df9086bf9c496c025bdf24",
      measurementId: "G-PZ9XL79R82"
    };
    if (!firebase.apps.length) {
      firebase.initializeApp(this.firebaseConfigState);
    } else {
      firebase.app();
    }
    window.addEventListener('message', (event) => {
      // Check the origin to ensure the message is from a trusted source
      if (event.origin == process.env.VUE_APP_BASE_URL_LOGIN.slice(0,-1)) { // Replace with your actual popup origin
        let res = event.data
        this.$store.commit(SET_AUTH, event.data.user)
        let path = '/dashboard';
        if (res.user.is_confirm !== 2 && res.user.is_confirm !== 4) {
          this.$router.push({path: `/update-user/${res.user.id}`})
        } else {
          if (path === '/reset-password' || path === '/forgot-password' || path === '/register' || path.includes('update-user')) path = '/dashboard';
          let role_id = res.user && res.user.account_type ? res.user.account_type.noibo_role_id : '';
          let candidate_status = res.candidate ? res.candidate.status : '';
          if (candidate_status == 3 && role_id != '32') {
            path = '/profile'
          }
          if (role_id == '30' || role_id == '31' || role_id == '32' || role_id == '33' || role_id == '37' || role_id == '38') {
            if (res.user.profile.status_verify == 0 || res.user.profile.status_verify == 3) {
              path = '/profile'
            }
          }
          this.$router.push({path: path})
        }
      }
    });
  },
  methods: {
    delete_cookie(name) {
      document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    },
    loginEdutalk() {
      document.getElementById('ifram1').src = process.env.VUE_APP_BASE_URL_LOGIN + 'login?from=noibo';
      var divContents = document.getElementById('modal').innerHTML;
      var modalWindow = window.open(process.env.VUE_APP_BASE_URL_LOGIN + "login?from=noibo","Login with Edutalk",'height=550,width=650,top=350,left=600');
      // modalWindow.document.write('<html>');
      // modalWindow.document.write('<meta name="viewport" content="width=device-width, initial-scale=1.0">');
      // modalWindow.document.write('<title>Log in to Edutalk | Edutalk</title>');
      // modalWindow.document.write('</body></html>');
      // modalWindow.document.close();
    },
    loginFacebook() {
      const providerFacebook = new firebase.auth.FacebookAuthProvider();
      this.$gtag.event('login', {method: 'Facebook'})
      firebase.auth().signInWithPopup(providerFacebook).then((result) => {
        let profile = result.additionalUserInfo.profile;
        let data = {
          email: profile.email,
          name: profile.name,
          provider_id: profile.id,
        }
        this.$store.dispatch(FACEBOOK_LOGIN, data).then((result) => {
          // this.$router.push({path: '/dashboard'})
          let oldPath = this.$store.state.config.oldPath;
          let path = '/dashboard';
          if (oldPath !== '') {
            path = oldPath;
          }
          if (result.user.is_confirm !== 2 && result.user.is_confirm !== 4) {
            this.$router.push({path: `/update-user/${result.user.id}`})
          } else {
            if (path === '/reset-password' || path === '/forgot-password' || path === '/register' || path.includes('update-user')) path = '/dashboard';
            let role_id = result.user && result.user.account_type ? result.user.account_type.noibo_role_id : '';
            if (role_id == '30' || role_id == '31' || role_id == '32' || role_id == '33' || role_id == '37' || role_id == '38') {
              if (result.user.profile.status_verify == 0 || result.user.profile.status_verify == 3) {
                path = '/profile'
              }
            }
            this.$router.push({path: path})
          }
        }).catch((error) => {
          console.log(error)
        });
      }).catch((error) => {
        console.log(error)
      })
    },

    loginGoogle() {
      const providerGoogle = new firebase.auth.GoogleAuthProvider();
      this.$gtag.event('login', {method: 'Google'})
      firebase.auth().signInWithPopup(providerGoogle).then((result) => {
        let profile = result.additionalUserInfo.profile;
        let data = {
          email: profile.email,
          name: profile.name,
          provider_id: profile.id,
        }
        this.$store.dispatch(GOOGLE_LOGIN, data).then((result) => {
          let oldPath = this.$store.state.config.oldPath;
          let path = '/dashboard';
          if (oldPath !== '') {
            path = oldPath;
          }
          if (result.user.is_confirm !== 2 && result.user.is_confirm !== 4) {
            this.$router.push({path: `/update-user/${result.user.id}`})
          } else {
            if (path === '/reset-password' || path === '/forgot-password' || path === '/register' || path.includes('update-user')) path = '/dashboard';
            let role_id = result.user && result.user.account_type ? result.user.account_type.noibo_role_id : '';
            if (role_id == '30' || role_id == '31' || role_id == '32' || role_id == '33' || role_id == '37' || role_id == '38') {
              if (result.user.profile.status_verify == 0 || result.user.profile.status_verify == 3) {
                path = '/profile'
              }
            }
            this.$router.push({path: path})

          }
          // this.$router.push({path: '/dashboard'})
        }).catch((error) => {
          console.log(error)
        });
      }).catch((error) => {
        console.log(error)
      })
    },
    onSubmit() {
      var email = this.form.email;
      var password = this.form.password;
      let path = '/dashboard';
      this.$gtag.event('login', {method: 'Email'})
      this.$store
          .dispatch(LOGIN, {email, password})
          .then((res) => {
            if (res.user.is_confirm !== 2 && res.user.is_confirm !== 4) {
              this.$router.push({path: `/update-user/${res.user.id}`})
            } else {
              if (path === '/reset-password' || path === '/forgot-password' || path === '/register' || path.includes('update-user')) path = '/dashboard';
              let role_id = res.user && res.user.account_type ? res.user.account_type.noibo_role_id : '';
              let candidate_status = res.candidate ? res.candidate.status : '';
              if (candidate_status == 3 && role_id != '32' && res.user.is_confirm != 2) {
                path = '/profile'
              }
              if (role_id == '30' || role_id == '31' || role_id == '32' || role_id == '33' || role_id == '37' || role_id == '38') {
                if (res.user.profile.status_verify == 0 || res.user.profile.status_verify == 3) {
                  path = '/profile'
                }
              }
              this.$router.push({path: path})

            }
          }).then(() => {
            let params = { is_wait: 1};
            this.$store.dispatch(COUNT_IS_WAIT_USER, params);
          }).catch((error) => {
          });
    },

  }
};
</script>
